<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "lianShanZong",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(金乌岛场景会失败,退出金乌岛再初始环境,如果失败看菜单初始环境教程)(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到金乌岛设置页",
                            "#按图1说明参数:",
                            "1.必填项-切换输出套路:输出套路是指你的角色主套路,用来打怪的,根据快捷栏设置切换快捷键" +
                            "(9小代表小键盘的9按键,其他带小的是同理)",
                            "2.必填项-切换五气堡套路:五气堡武学修炼专用,此功能无需设置",
                            "3.必填项-技能:上面切换套路后,技能对应的快捷键,根据快捷栏设置",
                        ],
                        img:[
                            "com/jinwu.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到廉山宗页",
                            "#按图1说明参数:",
                            "1.必填项-勾选要做的任务,如果已完成的任务需要取消勾选,已接取的任务需要放弃,让助手自动接取",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            "#以上设置好,开始任务",
                        ],
                        img:[
                            "com/jinwudao.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
